import * as React from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import adorno from '@images/adorno/adorno2.png';
import img1 from '@images/objetivos/obj2/square-academic-cap-svgrepo-com.svg';
import img2 from '@images/objetivos/obj2/computer-svgrepo-com.svg';
import img3 from '@images/objetivos/obj2/class-group-svgrepo-com.svg';
import { Link } from "gatsby";

const Wrapper = styled.div`
padding-top: 1rem;
display: flex;
flex-direction: column;
overflow: hidden;
`;



const IndexPage = () => {




    return (
        <TemplatePage1
            title={"Promover el desarrollo de capacidades de investigación en sistemas de salud en la región de LAC"}
            showtitle={false}
            adorno={<Adorno />}
            content={(
                <Wrapper >

                    <CardContainer>
                        <TitleGroup>
                            <div>
                                <h1>Objetivo</h1>
                                <h2>2</h2>
                            </div>
                            <p>
                                Promover el desarrollo de capacidades de investigación en sistemas de salud en la región de LAC
                            </p>
                        </TitleGroup>
                        <Card width={"80%"}>
                            <img src={img1} alt={img1} />
                            <div>
                                <h1><b>Formación de capacidades en investigación</b></h1>
                                <p>Formación a nivel de maestrías, doctorados y postdoctorados para el desarrollo de investigadores en calidad de sistemas de salud.</p>
                                <Link to={"/actividades/convocatorias"} >Ver convocatorias</Link>
                            </div>

                        </Card>
                        <Card width={"70%"}>
                            <img src={img2} alt={img2} />
                            <div>
                                <h1><b>Webinars</b></h1>
                                <p>Webinars con invitados especiales del Consorcio Latinoamericano de Innovación, Calidad y Seguridad en Salud. </p>
                                <Link to={"/actividades/webinars"}>Ver más</Link>
                            </div>

                        </Card>
                        <Card width={"60%"}>
                            <img src={img3} alt={img3} />
                            <div>
                                <h1><b>Cursos con participación de ponentes internacionales</b></h1>
                                <p>Cursos avalados por el Comité Asesor de Políticas Regionales (CAPR)</p>
                                <Link to={"/actividades/cursos-cortos"}>Ver más</Link>
                            </div>

                        </Card>
                    </CardContainer>
                </Wrapper >
            )}
        />
    )
};

export default IndexPage;

const Adorno = styled.div`
position: absolute;
top: -4px;
height:120%;
left:0;  
background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
@media only screen and (min-width:768px){
    top: 50px;
  width: 840px;
  left:-500px;

 }
 @media only screen and (min-width:1200px){
    top: -4px;
    left:-300px;
}
@media only screen and (min-width:1650px){
    left:0;
}
`;

const CardContainer = styled.div`
display: flex;
flex-direction:column;
display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-end;
	align-content: stretch;



`;

const Card = styled.div`
padding:5px 4px;


@media only screen and (min-width:768px){
    h1{
        font-size:24px;
    }

    p{
        font-size:20px;
    }

    
}


@media only screen and (min-width:768px){
    width: ${props => props.width ? props.width : "100%"};
    justify-content: center;
    text-align: left;
}
    width:100%;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: stretch;
    padding: 10px;
    text-align: right;
    
    div{
        width:80%;
    }


    img{
        width: 100px;
        margin: 2px;
        height: 100px;
    }

    a{
        color: white;
        background-color: var(--color2);
        padding: 5px 10px;
        margin: 20px;
    }


    



`

const TitleGroup = styled.div`
@media only screen and (min-width:1024px){
    width:80%;
}



display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: right;


div{
  display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
  h1{
    color: var(--tema4);
  font-size: 18px;
  margin:0;
}

h2{
  font-size: 100px;
  line-height: 100px;
  color:white;
  font-weight: bold;
  text-shadow: 0px 0px 4px var(--tema4);
  margin:0;
}
}
p{
  font-size: 24px;
  font-weight: bold;
  color: var(--tema4);
}

`;
